import { FC } from "react";
import { icons } from "../../icons/IconSet";
import InfoWidget from "../common/widgets/InfoWidget";
import { IOrder, IOrderItem } from "../../model/IOrder";
import arrayToNodeString from "../../utils/arrayToNodeString";
import Divider from "../common/Divider";
import { useAccess } from "../../hooks/useAccess";
import priceFormatter from "../../utils/priceFormatter";

export interface ITicketOrderDetails {
  orderItemId?: IOrderItem['id'],
  id?: IOrder['id'],
  orderId?: IOrder['orderId'],
  siTrackingNum?: IOrder['siTrackingNum'],
  invoice?: IOrder['invoice'],
  fromSite?: IOrder['fromSite'],
  siName?: IOrder['siName'],
  timeCreate?: IOrder['timeCreate'],
  siEmail?: IOrder['siEmail'],
  siPhone?: IOrder['siPhone'],
  siZip?: IOrder['siZip'],
  siState?: IOrder['siState'],
  siCity?: IOrder['siCity'],
  siStreet?: IOrder['siStreet'],  
  subtotalPrice?: IOrder['subtotalPrice'],
  shipping?: IOrder['shipping'],
  taxes?: IOrder['taxes'],
  totalPrice?: IOrder['totalPrice'],
  orderItems?: {
    id?: IOrderItem['id'],
    pid?: IOrderItem['pid'],
    name?: IOrderItem['name'],
  }[]
}

const TicketOrderDetails: FC<ITicketOrderDetails> = ({
  orderItemId,
  id,
  orderId,
  timeCreate,
  siName,
  siEmail,
  siPhone,
  siZip,
  siState,
  siCity,
  siStreet,
  orderItems,
  fromSite,
  subtotalPrice,
  shipping,
  taxes,
  totalPrice,
  siTrackingNum,
  invoice,
}) => {
  const { getSettingAccess } = useAccess();
  
  return !orderId
    ? null
    : (
      <div className="flex flex-col gap-4">
        <div className="flex gap-2 text-md items-center mt-4 font-bold">
          {icons('user')}
          {siName}
        </div>   
        <InfoWidget title="Email">
          {siEmail ?? '-'}
        </InfoWidget>
        <InfoWidget title="Primary Phone">
          {siPhone ?? '-'}
        </InfoWidget>
        <InfoWidget title="Shipping Address">
          {arrayToNodeString(', ', [siStreet, siCity, siState, siZip])} 
        </InfoWidget>
        <Divider />
        <InfoWidget title={`Product Name${orderItems && orderItems?.length > 1 && !orderItemId ? 's' : ''}`}>
          {(orderItemId && orderItems
            ? [orderItems.find(({ id }) => id === orderItemId) || {}]
            : orderItems || []
          ).map(({ pid, name }, i) => <div key={`${name}-${i}`}>#{pid}, {name}</div>)}
        </InfoWidget>
        <InfoWidget title="Order #">
          {orderId ?? '-'}
        </InfoWidget>
        <InfoWidget title="Purchased">
          {timeCreate ?? '-'}
        </InfoWidget>
        <InfoWidget title="Tracking #">
          {siTrackingNum ?? '-'}
        </InfoWidget>
        <InfoWidget title="Invoice #">
          {invoice ?? '-'}
        </InfoWidget>
        <InfoWidget title="Website">
          {fromSite ?? '-'}
        </InfoWidget>
        {!!getSettingAccess('order-priceFields') && (
          <InfoWidget title="Price">
            {Object.entries({
              'Part Price': priceFormatter(subtotalPrice),
              'Shipping': priceFormatter(shipping),
              'Taxes': priceFormatter(taxes),
              'Total': priceFormatter(totalPrice),
            }).map(([key, value]) => (
              <div key={key} className="flex">
                <div className="w-[80px]">{key}:</div>
                <div className="">{value}</div>
              </div>
            ))}
          </InfoWidget>
        )}

        {!!getSettingAccess('order-details') && (
          <div className="">
            <a
              className="text-primary hover:text-primary_dark"
              href={`${process.env.REACT_APP_BACKEND_URL}/lkq/order-update/${orderId}/`}
              target="_blank"
            >
              View Order Details
            </a>
          </div>
        )}

      </div>
    );
}

export default TicketOrderDetails;
